import {
  Modal,
  OfficeButton,
  OfficeHeader,
  RadioInput,
  RichEditor,
  SelectInput,
  Show,
  TextInput,
  toast,
} from 'components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { uniqueId } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import SectionLayout from './components/SectionLayout';
import VariationOptionInput from './components/VariationOptionInput';
import { dispatchAction, useUrlState } from 'utils';
import { useCreateProduct } from './hooks/use-create-product';
import {
  CountryCode,
  CurrencyCode,
  ProductOrigin,
  ProductStatus,
  ProductType,
} from '_graphql/__generated__/globalTypes';
import { v4 } from 'uuid';
import { StarIcon, TrashIcon } from '@heroicons/react/24/solid';
import {
  useAppNaviagte,
  useAppSearch,
  useCurrentUser,
} from '_graphql/cache/auth';
import { useCategories } from 'pages/category/hooks/use-categories';
import useUploadMedia, { IFile } from './hooks/use-upload-media';
import { OptionsType } from './types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { PlusIcon } from '@heroicons/react/20/solid';
import Toggle from './components/Toggle';
import _ from 'lodash';
import { useGetSKU } from './hooks/use-get-sku';
import { cartesian, generateCombinationsColumns } from 'utils/helpers';
import CombinationTable from './components/CombinationTable';
import CreateCategoryContainer from 'pages/category/create';
import { useTemplates } from './hooks/use-templates';
import { useTemplate } from './hooks/use-template';
import TemplateRenderer from 'pages/create-product/components/TemplateRenderer';
import { GetProductTemplate_getProductTemplate } from '_graphql/queries/__generated__/GetProductTemplate';

type Props = {};

const CreateProductPage: FC<Props> = (props) => {
  const user = useCurrentUser();
  const searchParams = useAppSearch();
  const navigate = useAppNaviagte();

  const options = [
    { value: 'size', label: 'Size' },
    { value: 'color', label: 'Color' },
    { value: 'style', label: 'Style' },
    { value: 'brand', label: 'Brand' },
  ];

  const [variationValue, setVariationValue] = useState<
    {
      variation_name: string;
      variation_options: any[];
    }[]
  >([]);

  const [variationCombinationInput, setVariationCombinationInput] = useState<
    Record<string, any>[]
  >([]);

  // useTitle('Products | Create Product');
  const mediaUploadRef = useRef<HTMLInputElement>(null);
  const { categories, loading, fetchMore, totalCount } = useCategories({
    variables: {
      filter: {
        supplier_id: {
          eq: user?.id,
        },
      },
    },
  });

  const { templates, loading: loadingTemplates } = useTemplates();

  const [, setTags] = useState<OptionsType<any>>([]);
  const [avatarindex, setAvatarindex] = useState(0);
  const [, setCategories] = useState<any[]>([{}]);
  const [, setDistribution] = useState<any[]>([{}]);
  const [previewModal, setPreviewModal] = useState<boolean>(false);
  const [doneAction, setDoneAction] = useState<boolean>(false);

  const emptyOptions: Record<string, any>[] = [];
  const { sku, loading: loadingSku, refetch } = useGetSKU();

  const [inputArray, setInputArray] = useState<Record<string, any>[]>([
    {
      name: 'variation_name',
      id: 'variation_name',
      options,
      optionName: 'variation_options',
    },
  ]);

  const { loading: creating, productCreation } = useCreateProduct();

  const { ...digitalDetailsForm } = useFormik({
    initialValues: {},
    onSubmit: () => {},
  });

  const { handleSubmit, resetForm, ...productInfoForm } = useFormik({
    initialValues: {
      productType: ProductType.Physical,
      availabiltyRadio: 'limited',
      country: user.country,
      cost_price: '',
      variantStatus: 'single',
      profit: 0,
      moq: 1,
      categories: [],
      currency:
        user.country === 'GH'
          ? 'GHS'
          : user.country === 'NG'
            ? 'NGN'
            : user.country === 'ZA'
              ? 'ZAR'
              : '',
      media: [],
      accessType: '',
      attachedFiles: [],
      subType: '',
      price: '',
      qty: '',
      sku: '',
      description: '',
      weight: '',
      title: '',
      avatar: '',
      distribution: [] as string[],
      tags: [],
      extraInfo: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Product name is required'),
      country: Yup.string().required('Country is required'),
      currency: Yup.string().required('Currency is required'),
      sku: Yup.string().required('SKU is required'),
      moq: Yup.string().required('Minimum order quantity is required'),
      price: Yup.string().required('Selling price is required'),
      cost_price: Yup.string().required('Cost price is required'),
      description: Yup.string().required('Description is required'),
      distribution: Yup.array()
        .min(1, 'Choose at least one distribution channel')
        .required('Distribution is required'),
      media: Yup.array().when('productType', {
        is: ProductType.Physical,
        then: (schema) =>
          schema
            .min(2, 'Select at least two pictures')
            .required('Media is required'),
        otherwise: (schema) =>
          schema
            .min(1, 'Select at least one picture')
            .required('Media is required'),
      }),
      categories: Yup.array()
        .min(1, 'Choose at least one category')
        .required('Category is required'),
    }),
    onSubmit: async (values) => {
      if (values.variantStatus === 'single') {
        await productCreation({
          variables: {
            input: {
              country: values.country as CountryCode,
              cost_price: values.distribution?.includes('TendoNetwork')
                ? Number(values.price)
                : Number(values.cost_price),
              categories: values.categories,
              currency: values.currency as CurrencyCode,
              media: values.media,
              price: values.distribution?.includes('TendoNetwork')
                ? 0
                : Number(values.price),
              supplier_cost: Number(values.cost_price),
              sku: values.sku,
              tags: values.tags,
              distributionChannels: values.distribution,
              description: values.description,
              limited_stock:
                values.productType === ProductType.Digital
                  ? false
                  : values.availabiltyRadio === 'limited',
              weight: Number(values.weight),
              title: values.title,
              avatar: values.avatar,
              minimumOrderQuantity: values.moq,
              uuid: v4(),
              verified: false,
              product_origin: ProductOrigin.SUPPLIER_TENDO_ONLINE_CATALOGUE,
              extra_information: values.extraInfo,
              digitalDetails:
                values.productType === ProductType.Digital
                  ? digitalDetailsForm.values
                  : undefined,
              inhouse: false,
              variations: null,
              supplier_id: user?.id,
              published: false,
              productType: values.productType,
              personnel: 'Supplier',
              status: ProductStatus.PENDING,
              qty:
                values.productType === ProductType.Digital
                  ? 1000000
                  : values.availabiltyRadio === 'limited'
                    ? Number(values.qty)
                    : 1000000,
            },
          },
        })
          .then(({ data }) => {
            if (data?.createProduct) {
              toast('Product created successfully', 'success');
              resetForm();
              digitalDetailsForm.resetForm();
              refetch();
              navigate({
                to: '/products',
              });
            } else {
              toast('Something went wrong', 'error');
            }
          })
          .catch((err) => {
            toast(err.message, 'error');
          });
      } else {
        const input = Object.assign({}, values, {
          combinations: variationCombinationInput.map((input) => {
            return {
              sku: input.sku,
              price: 0,
              combination_string: input.combination_string,
              qty: Number(input.available_stock),
              cost_price: Number(input.price),
              avatar: input.avatar,
              media: input.media,
              categories: values.categories,
            };
          }),
          inhouse: false,
          verified: false,
          uuid: v4(),
          published: false,
          variations: variationValue.map((variation) => {
            return {
              ...variation,
              variation_options: variation.variation_options.map((option) => {
                return {
                  variation_value: option,
                };
              }),
            };
          }),
        });
        await productCreation({
          variables: {
            input: {
              country: input.country as CountryCode,
              combinations: input.combinations,
              cost_price: Number(input.price),
              supplier_cost: Number(input.cost_price),
              categories: input.categories,
              currency: input.currency as CurrencyCode,
              media: input.media,
              price: 0,
              productType: values.productType,
              sku: input.sku,
              tags: input.tags,
              distributionChannels: input.distribution,
              description: input.description,
              digitalDetails:
                values.productType === ProductType.Digital
                  ? digitalDetailsForm.values
                  : undefined,
              limited_stock:
                values.productType === ProductType.Digital
                  ? false
                  : input.availabiltyRadio === 'limited',
              weight: Number(input.weight),
              title: input.title,
              avatar: input.avatar,
              minimumOrderQuantity: input.moq,
              uuid: v4(),
              verified: false,
              extra_information: input.extraInfo,
              inhouse: false,
              variations: input.variations,
              published: false,
              personnel: 'Supplier',
              status: ProductStatus.PENDING,
              product_origin: ProductOrigin.SUPPLIER_TENDO_ONLINE_CATALOGUE,
              supplier_id: user?.id,
              qty:
                values.productType === ProductType.Digital
                  ? 1000000
                  : input.availabiltyRadio === 'limited'
                    ? Number(values.qty)
                    : 1000000,
            },
          },
        })
          .then(({ data }) => {
            if (data?.createProduct) {
              toast('Product created successfully', 'success');
              resetForm();
              digitalDetailsForm.resetForm();
              refetch();
              navigate({
                to: '/products',
              });
            } else {
              toast('Something went wrong', 'error');
            }
          })
          .catch((err) => {
            toast(err.message, 'error');
          });
      }
    },
  });

  // Template Manipulations

  const {
    template,
    loading: loadingTemplate,
    refetch: templateRefetch,
  } = useTemplate({
    filter: {
      id: productInfoForm.values.subType
        ? { eq: productInfoForm.values.subType }
        : undefined,
    },
  });

  /** Media manipulation */
  const onImagesUploaded = (images: IFile[]) => {
    productInfoForm.setFieldValue('avatar', images[avatarindex]?.imgLink);
    images?.forEach((file, i) => {
      productInfoForm.setFieldValue(`media.${i}`, file.imgLink);
    });
  };

  const {
    getInputProps,
    isDragActive,
    uploadingMedia,
    deleteFile,
    getRootProps,
  } = useUploadMedia(uniqueId('product-'), {
    onUploaded: onImagesUploaded,
    multiple: true,
  });

  const mediaUploadClickHandler = () => {
    if (mediaUploadRef.current) {
      mediaUploadRef.current.click();
    }
  };

  const handleAvatarChoose = (index: number) => {
    setAvatarindex(index);
    productInfoForm.setFieldValue('avatar', uploadingMedia[index]?.imgLink);
  };

  const init = useRef({
    fetchMore,
  });

  /** Category manipulation */
  const loadMoreCategory = async () => {
    const { fetchMore } = init.current;
    await fetchMore({
      variables: {
        filter: {},
        pagination: {
          offset: categories.length,
          limit: 10,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const newData = fetchMoreResult.getProductCategories ?? [];
        const prevData = prev.getProductCategories ?? [];
        return Object.assign({}, prev, {
          getProductCategories: [...prevData, ...newData],
        });
      },
    });
  };

  const handleCategoriesChange = (e: any) => {
    setCategories(e ?? [{}]);
    e?.forEach((el: any, index: number) => {
      productInfoForm.setFieldValue(`categories.${index}`, el?.value);
    });
  };

  /** Distribution manipulation */
  const handleDistributionChange = (e: any) => {
    setDistribution(e ?? [{}]);

    e?.forEach((el: any, index: number) => {
      productInfoForm.setFieldValue(`distribution.${index}`, el?.value);
    });
  };

  /** Tags manipulation */
  const handleTagChanges = (e: any) => {
    setTags(e ?? []);
    e?.forEach((el: any, index: number) => {
      productInfoForm.setFieldValue(`tags.${index}`, el?.value);
    });
  };

  /** Variation manipulation */
  const onVariationInputRemove = (index: number) => {
    if (inputArray.length === 1) return;
    setInputArray(inputArray.filter((obj, i) => i !== index));
    setVariationValue(variationValue.filter((obj, i) => i !== index));
  };

  const onVariationInputChange = (e: Record<string, any>, index: number) => {
    setVariationValue((preValue) => [
      ...preValue.filter(
        (obj, i) => obj.variation_name !== e.value && i !== index
      ),
      { variation_name: e?.value, variation_options: [] },
    ]);
  };

  const onVariationInputAdd = (
    index: number,
    options: Record<string, any>[]
  ) => {
    const newObj: Record<string, any> = {};
    newObj.name = `variation_name_${index}`;
    newObj.id = `variation_name_${index}`;
    newObj.options = options;
    newObj.optionName = `variation_options_${index}`;

    setInputArray([...inputArray, newObj]);
  };

  const onVariationOptionsChange = (e: OptionsType<any>, index: number) => {
    const tempVariationValue = variationValue || [{}];
    if (
      tempVariationValue[index] &&
      tempVariationValue[index].variation_options
    ) {
      tempVariationValue[index].variation_options = e?.map(
        (o: { [x: string]: any }) => o['value'] ?? []
      ) as never[];
    }
    setVariationValue([...tempVariationValue]);
  };
  const temp = variationValue?.map((x) => {
    return x.variation_options;
  });

  const combinationData = cartesian(...(temp || []));

  const onNextClicked = () => {
    const value = productInfoForm.values;
    setVariationCombinationInput(
      () =>
        generateCombinationsColumns(variationValue, combinationData).map(
          (cbx: { [s: string]: unknown } | ArrayLike<unknown>, index) => ({
            ...cbx,
            available_stock:
              productInfoForm.values.productType === ProductType.Digital
                ? 100000
                : productInfoForm.values.availabiltyRadio === 'limited'
                  ? Number(productInfoForm.values.qty)
                  : 10000,
            sku: `${productInfoForm.values.sku}-${String(index).padStart(3, '0')}`,
            price: productInfoForm.values.price ?? 0,
            cost_price: value?.cost_price ?? 0,
            combination_string: Object.values(cbx).join('&'),
            avatar: productInfoForm.values.avatar,
            media: [],
          })
        ) ?? []
    );
    setPreviewModal(true);
  };

  useEffect(() => {
    if (sku) {
      productInfoForm.setFieldValue('sku', sku);
    } else {
      refetch();
    }
  }, [loadingSku, sku]);

  useEffect(() => {
    if (productInfoForm.touched) {
      setDoneAction(false);
    }
  }, [productInfoForm.values, productInfoForm.touched]);

  useEffect(() => {
    productInfoForm.setFieldValue(
      'profit',
      Number(productInfoForm.values.price) -
        Number(productInfoForm.values.cost_price)
    );
  }, [productInfoForm.values.price, productInfoForm.values.cost_price]);

  useEffect(() => {
    templateRefetch();
  }, [productInfoForm.values.subType]);

  return (
    <>
      <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
        <OfficeHeader />
        {/* heading */}

        {/* content */}
        <div className="w-full overflow-y-auto">
          <form className="w-full py-5">
            <div className=" grid grid-cols-1 md:grid-cols-5">
              <div className="w-full col-span-3">
                <SectionLayout title="Product Type">
                  <div className="col-span-full -mt-3  mb-2">
                    <RadioInput
                      id={'productType'}
                      label={''}
                      verticalAlign={true}
                      options={[
                        {
                          description: '',
                          label: 'Physical Product',
                          value: ProductType.Physical,
                        },
                        {
                          description: '',
                          label: 'Digital Product',
                          value: ProductType.Digital,
                        },
                      ]}
                      {...productInfoForm}
                    />
                  </div>
                  <Show
                    if={
                      productInfoForm.values.productType === ProductType.Digital
                    }
                  >
                    <div className="col-span-full">
                      <SelectInput
                        options={[
                          { label: 'Please Select Type', value: '' },
                          ...(templates?.map((info) => ({
                            label: info?.name,
                            value: info?.id,
                          })) || []),
                        ]}
                        id={'subType'}
                        label="Digital Type"
                        {...productInfoForm}
                      />
                    </div>
                  </Show>
                </SectionLayout>
                <SectionLayout title="Product Information">
                  <>
                    <div className="col-span-full">
                      <TextInput
                        id={'title'}
                        label="Product Name"
                        {...productInfoForm}
                      />
                    </div>
                    <div className="col-span-3">
                      <TextInput
                        id={'moq'}
                        label="Mininum Order Quantity"
                        type="number"
                        {...productInfoForm}
                      />
                    </div>
                    <div className="col-span-3">
                      <SelectInput
                        options={[
                          { label: 'Ghana', value: 'GH' },
                          { label: 'Nigeria', value: 'NG' },
                          { label: 'South Africa', value: 'ZA' },
                        ]}
                        id={'country'}
                        label="Country"
                        disabled
                        {...productInfoForm}
                      />
                    </div>
                    <div className="col-span-3">
                      <TextInput
                        id={'sku'}
                        label="Stock Keeping Unit"
                        placeholder="eg.200945"
                        {...productInfoForm}
                        disabled={true}
                      />
                      <div className="w-full flex justify-end items-center col-span-full ">
                        <button
                          type="button"
                          disabled={loadingSku}
                          className="text-xs p-1 my-1 hover:text-primary-500 "
                          onClick={() => refetch()}
                        >
                          {loadingSku ? 'Loading ....' : '  Generate New SKU'}
                        </button>
                      </div>
                    </div>
                    <Show
                      if={
                        productInfoForm.values.productType ===
                        ProductType.Physical
                      }
                    >
                      <div className="col-span-3">
                        <TextInput
                          id={'weight'}
                          label="Weight (Optional)"
                          placeholder="Weight(kg)"
                          {...productInfoForm}
                        />
                      </div>
                    </Show>
                    <div className="col-span-full">
                      <RichEditor
                        id={'description'}
                        label="Product Description"
                        {...productInfoForm}
                      />
                    </div>
                  </>
                </SectionLayout>
                <SectionLayout title="Product Pricing">
                  <div className="col-span-full">
                    <SelectInput
                      options={['GHS', 'NGN', 'ZAR']}
                      id={'currency'}
                      label="Currency"
                      {...productInfoForm}
                      disabled
                    />
                  </div>
                  <div className="col-span-3">
                    <TextInput
                      id={'price'}
                      label="Selling price"
                      type="number"
                      {...productInfoForm}
                    />
                  </div>
                  <div className="col-span-3">
                    <TextInput
                      id={'cost_price'}
                      label="Cost Per Item"
                      type="number"
                      {...productInfoForm}
                    />
                  </div>
                  <div className="col-span-full">
                    <TextInput
                      id={'profit'}
                      label="Profit"
                      type="number"
                      {...productInfoForm}
                      disabled
                    />
                  </div>
                </SectionLayout>
                <Show
                  if={
                    productInfoForm.values.productType === ProductType.Digital
                  }
                >
                  <SectionLayout title="Digital Product Information">
                    <div className="space-y-3 col-span-full">
                      <TemplateRenderer
                        form={digitalDetailsForm}
                        template={
                          template as GetProductTemplate_getProductTemplate
                        }
                      />
                      <div className="col-span-full">
                        <SelectInput
                          options={[
                            {
                              label: '---- Please Select Delivery Mode ----',
                              value: '',
                            },
                            { label: 'Send product via email', value: 'Email' },
                            {
                              label: 'Allow product download ',
                              value: 'Download',
                            },
                            {
                              label: 'Product accessible on storefront',
                              value: 'Preview',
                            },
                          ]}
                          id={'deliveryMode'}
                          label="Delivery Mode"
                          description="Select how this products should be delivered to the customer"
                          {...digitalDetailsForm}
                        />
                      </div>
                    </div>
                  </SectionLayout>
                </Show>
                <Show
                  if={
                    productInfoForm.values.productType === ProductType.Physical
                  }
                >
                  <SectionLayout title="Inventory Management">
                    <>
                      <div className="col-span-full ">
                        <RadioInput
                          id={'availabiltyRadio'}
                          label={'Availablity'}
                          verticalAlign={true}
                          options={[
                            {
                              description: '',
                              label: 'Limited Availablity',
                              value: 'limited',
                            },
                            {
                              description: '',
                              label: 'Always Available',
                              value: 'unlimited',
                            },
                          ]}
                          {...productInfoForm}
                        />
                      </div>
                      {productInfoForm.values.availabiltyRadio ===
                        'limited' && (
                        <div className="col-span-full">
                          <TextInput
                            description='Product will be set to "out of stock" when quantity reaches 0.'
                            id={'qty'}
                            label="Stock count (Quantity)"
                            placeholder="eg. 10"
                            {...productInfoForm}
                          />
                        </div>
                      )}
                    </>
                  </SectionLayout>
                </Show>
                <SectionLayout title="Product Media">
                  {/* Upload Image Field */}
                  <>
                    <div
                      {...getRootProps()}
                      className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer w-full col-span-full"
                      onClick={mediaUploadClickHandler}
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <input
                            {...getInputProps()}
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            ref={mediaUploadRef}
                            className="hidden sr-only"
                          />
                          {isDragActive ? (
                            <p>Drop the files here ...</p>
                          ) : (
                            <>
                              <label
                                htmlFor="file-upload"
                                className="relatives bg-white rounded-md font-medium   focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                              >
                                <span>Upload a file</span>
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </>
                          )}
                        </div>
                        <p className="text-xs text-gray-500">
                          PNG, JPG, GIF, MP4 up to 10MB
                        </p>
                      </div>
                    </div>

                    {uploadingMedia.length !== 0 && (
                      <div className="mt-2 col-span-full ">
                        <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mt-3 w-full">
                          {uploadingMedia.map((item, index) => (
                            <li
                              onClick={() => handleAvatarChoose(index)}
                              key={item?.imageName}
                              className="relative"
                            >
                              <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                                {item?.loading ? (
                                  <div className="flex justify-center items-center p-4">
                                    <span>Loading... </span>
                                  </div>
                                ) : (
                                  <img
                                    src={item?.imgLink}
                                    alt=""
                                    className="object-cover pointer-events-none group-hover:opacity-75"
                                  />
                                )}
                              </div>

                              <p className="mt-2  text-sm font-medium flex flex-col items-center text-gray-900 truncate pointer-events-none">
                                {index === avatarindex ? (
                                  <div className="flex">
                                    <StarIcon className="h-3 w-3 text-primary-600" />
                                    <span className="text-xs">
                                      Product Avatar
                                    </span>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {item?.imageName}{' '}
                              </p>

                              <span
                                className="md cursor-pointer hover:opacity-80 text-xs flex items-center justify-start"
                                onClick={() => deleteFile(index)}
                              >
                                <TrashIcon className="mr-2 text-red-500 h-4 w-4" />{' '}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </>
                </SectionLayout>
                {_.get(productInfoForm.errors, 'media') && (
                  <p className="mt-2 mb-5 mx-5 text-sm text-red-600 ">
                    {_.get(productInfoForm.errors, 'media')}
                  </p>
                )}
                <Show
                  if={
                    productInfoForm.values.productType === ProductType.Physical
                  }
                >
                  <SectionLayout
                    title="Product Variations"
                    description="If the product has multiple variants (options), like different tiers, packages, add-ons, sizes or colors please select (with variant) otherwise (without variant) "
                  >
                    <div className="relative w-full col-span-full">
                      <div className=" mb-4">
                        <div className="col-span-full ">
                          <RadioInput
                            id={'variantStatus'}
                            label={''}
                            verticalAlign={true}
                            options={[
                              {
                                description: '',
                                label: 'Without Variant',
                                value: 'single',
                              },
                              {
                                description: '',
                                label: 'With Variant',
                                value: 'variant',
                              },
                            ]}
                            {...productInfoForm}
                          />
                        </div>
                      </div>
                      <Show
                        if={productInfoForm.values.variantStatus === 'variant'}
                      >
                        <div className="flex flex-col mb-4 relative w-full ">
                          <div className="mb-6">
                            <div className="grid grid-cols-3 gap-4">
                              {inputArray?.map((x, idx) => (
                                <VariationOptionInput
                                  key={idx + 1}
                                  id={x.id}
                                  name={x.name}
                                  optionName={x.optionName}
                                  options={x.options}
                                  defaultValue={x.defaultValue}
                                  optionsDefault={x?.optionsDefault}
                                  removeFn={() => onVariationInputRemove(idx)}
                                  onSelectChange={(e) =>
                                    onVariationInputChange(e, idx)
                                  }
                                  onOptionChange={(e) =>
                                    onVariationOptionsChange(e, idx)
                                  }
                                />
                              ))}
                            </div>
                          </div>
                          <div className="flex justify-start">
                            <span
                              className="font-medium text-primary-500 p-2 rounded-md hover:bg-primary-500 hover:text-white cursor-pointer hover:opacity-80 text-xs flex items-center"
                              onClick={() =>
                                onVariationInputAdd(
                                  inputArray.length + 1,
                                  options
                                )
                              }
                            >
                              <PlusIcon className="mr-2 text-sm h-5" /> Add
                              another option
                            </span>
                          </div>
                        </div>
                      </Show>
                    </div>
                  </SectionLayout>
                </Show>
                <SectionLayout
                  title="Extra Information"
                  description="Extra information on the product"
                >
                  <>
                    <div className="col-span-full">
                      <RichEditor
                        id={'extraInfo'}
                        label=""
                        {...productInfoForm}
                      />
                    </div>
                  </>
                </SectionLayout>
              </div>
              {/* Right Side */}
              <div className="md:col-span-2">
                <div className="md:sticky top-6">
                  <div className="flex flex-col">
                    <SectionLayout title="Category and Tags">
                      <div className="col-span-full">
                        <div className="flex justify-between">
                          <label
                            htmlFor="category"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Category
                          </label>
                          <label
                            htmlFor="add-category"
                            onClick={dispatchAction('', 'configure', navigate)}
                            className="block text-xs cursor-pointer font-medium text-gray-700"
                          >
                            Add New Category
                          </label>
                        </div>
                        <p className="block text-xs text-gray-500 my-1">
                          Select categories to assign the product. A product can
                          be assign to multiple categories. Use the add button
                          to add a new category
                        </p>
                        <Select
                          id="categories"
                          isMulti
                          isLoading={loading}
                          className="basic-single text-sm"
                          classNamePrefix="select"
                          placeholder="Select categories"
                          noOptionsMessage={() => 'No category available'}
                          isSearchable={true}
                          options={categories?.map((category) => {
                            return {
                              value: category?.id,
                              label: category?.name,
                            };
                          })}
                          onChange={handleCategoriesChange}
                          // tabSelectsValue
                          // isLoading={networkStatus === NetworkStatus.loading}
                          onMenuScrollToBottom={async () => {
                            if (totalCount) {
                              if (categories.length < totalCount) {
                                await loadMoreCategory();
                              }
                            }
                          }}
                        />
                        {_.get(productInfoForm.errors, 'categories') && (
                          <p className="mt-2 mb-5 text-sm text-red-600 ">
                            {_.get(productInfoForm.errors, 'categories')}
                          </p>
                        )}
                      </div>
                      <div className="col-span-full">
                        <label
                          htmlFor="tag"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Tags
                        </label>
                        <p className="block text-xs text-gray-500 my-1">
                          Enter the tags for the product.
                        </p>
                        <CreatableSelect
                          id="tags"
                          isMulti
                          className="basic-single text-sm"
                          classNamePrefix="select"
                          defaultValue={emptyOptions[0]}
                          isSearchable={true}
                          onChange={handleTagChanges}
                          options={emptyOptions}
                          isClearable={true}
                          tabSelectsValue
                          placeholder="Enter tags for the product"
                          {...productInfoForm}
                        />
                      </div>
                    </SectionLayout>
                    <SectionLayout title="Publish">
                      <>
                        <div className="col-span-full">
                          <label
                            htmlFor="distribution"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Distribution
                          </label>
                          <p className="block text-xs text-gray-500 my-1">
                            Select distribution channel to assign the product. A
                            product can be assign to multiple distribution
                            channel.
                          </p>
                          <Select
                            id="distribution"
                            isMulti
                            className="basic-single text-sm"
                            classNamePrefix="select"
                            placeholder="Select distribution channel"
                            noOptionsMessage={() => 'No category available'}
                            isSearchable={true}
                            options={[
                              'TendoNetwork',
                              'SupplierStorefront',
                              'ResellerNetwork',
                              'MarketPlace',
                            ].map((el) => ({
                              value: el,
                              label: _.startCase(el),
                            }))}
                            onChange={handleDistributionChange}
                          />
                          {_.get(productInfoForm.errors, 'distribution') && (
                            <p className="mt-2 mb-5 text-sm text-red-600 ">
                              {_.get(productInfoForm.errors, 'distribution')}
                            </p>
                          )}
                        </div>
                        <div className="col-span-full flex items-center justify-between">
                          <OfficeButton
                            size="sm"
                            width="auto"
                            variant="danger"
                            onClick={() => {
                              resetForm();
                              navigate({ to: '/products' });
                            }}
                          >
                            Discard
                          </OfficeButton>
                          {productInfoForm.values.variantStatus ===
                          'variant' ? (
                            doneAction || creating ? (
                              <OfficeButton
                                onClick={handleSubmit}
                                size="sm"
                                width="auto"
                                disabled={creating}
                                type="submit"
                              >
                                {creating ? 'Saving...' : 'Save'}
                              </OfficeButton>
                            ) : (
                              <OfficeButton onClick={onNextClicked}>
                                Preview
                              </OfficeButton>
                            )
                          ) : (
                            <OfficeButton
                              onClick={handleSubmit}
                              size="sm"
                              width="auto"
                              disabled={creating}
                              type="submit"
                            >
                              {creating ? 'Saving...' : 'Save'}
                            </OfficeButton>
                          )}
                        </div>
                      </>
                    </SectionLayout>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
      <Modal
        title={'Product Combinations'}
        size="7xl"
        description="This is an auto generate combination of product base on the variation. Edit,Add and Remove per stock availability"
        open={
          previewModal && productInfoForm.values.variantStatus === 'variant'
        }
        setOpen={setPreviewModal}
        renderActions={() => (
          <div className="ml-3">
            <OfficeButton
              size="lg"
              onClick={() => {
                setPreviewModal(false);
                setDoneAction(true);
              }}
            >
              Save Combinations
            </OfficeButton>
          </div>
        )}
      >
        <div className="flex flex-col py-4 px-6 col-span-full">
          <CombinationTable
            variation={variationValue}
            combinationData={combinationData}
            imgSrc={productInfoForm.values.avatar}
            price={Number(productInfoForm.values.price)}
            uploadedMedia={uploadingMedia}
            formInputs={variationCombinationInput}
            setFormInputs={setVariationCombinationInput}
          />
        </div>
      </Modal>

      {searchParams?.modal && (
        <CreateCategoryContainer
          open={searchParams.modal === 'configure'}
          setOpen={() => {
            navigate({
              search(prev) {
                return {
                  ...prev,
                  modal: undefined,
                  id: undefined,
                };
              },
            });
          }}
        />
      )}
    </>
  );
};

export default CreateProductPage;
